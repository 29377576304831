import { createRouter, createWebHistory } from 'vue-router';

import { BookingStatus, useGraduationStore } from '@/stores/graduation';
import { useReunionStore } from '@/stores/reunion';
import { useUserStore } from '@/stores/user';
import GraduationHome from '@/views/graduation/home-view.vue';
import HomeView from '@/views/home-view.vue';
import notFound from '@/views/not-found.vue';
import ReunionHome from '@/views/reunion/home-view.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/confirmation',
      name: 'orderConfirmation',
      component: () => import('@/views/confirm-view.vue'),
      beforeEnter: async (to, from, next) => {
        const userStore = useUserStore();
        await userStore.getUser();
        if (userStore.visBookUser && to.query.orderGroupId) {
          next();
        }
        else {
          next('/');
        }
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login-view.vue'),
      beforeEnter: async (to, from, next) => {
        const userStore = useUserStore();
        await userStore.getUser();
        if (!to.query.redirectTo)
          next('/');
        if (userStore.visBookUser) {
          // @ts-expect-error okay
          next(to.query.redirectTo);
        }
        else {
          next();
        }
      },
    },
    {
      path: '/reunion',
      children: [
        {
          path: '',
          name: 'reunionHome',
          component: ReunionHome,
        },
        {
          path: 'ticket',
          name: 'reunionTicket',
          component: () => import('@/views/reunion/ticket-view.vue'),
          beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const reunionStore = useReunionStore();
            await userStore.getUser();
            await reunionStore.getBookingStatus();
            if (userStore.visBookUser && reunionStore.bookingOpen === BookingStatus.OPEN) {
              next();
            }
            else {
              next({ name: 'reunionHome' });
            }
          },
        },
        {
          path: 'customer',
          name: 'reunionCustomer',
          component: () => import('@/views/reunion/customer-view.vue'),
          beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const reunionStore = useReunionStore();
            await userStore.getUser();
            await reunionStore.getBookingStatus();
            if (userStore.visBookUser && reunionStore.bookingOpen === BookingStatus.OPEN) {
              next();
            }
            else {
              next({ name: 'reunionHome' });
            }
          },
        },
        {
          path: 'checkout',
          name: 'reunionCheckout',
          component: () => import('@/views/reunion/checkout-view.vue'),
          beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const reunionStore = useReunionStore();
            await userStore.getUser();
            await reunionStore.getBookingStatus();
            if (userStore.visBookUser && reunionStore.bookingOpen === BookingStatus.OPEN) {
              next();
            }
            else {
              next({ name: 'reunionHome' });
            }
          },
        },
      ],
    },
    {
      path: '/graduation',
      children: [
        {
          path: '',
          name: 'graduationHome',
          component: GraduationHome,
        },
        {
          path: 'rooms',
          name: 'graduationRooms',
          component: () => import('@/views/graduation/rooms-view.vue'),
          beforeEnter: async (to, from, next) => {
            const graduationStore = useGraduationStore();
            await graduationStore.getBookingStatus();
            if (graduationStore.bookingOpen === BookingStatus.OPEN && !graduationStore.cart.selectedRoom) {
              next();
            }
            else {
              next({ name: 'graduationGuests' });
            }
          },
        },
        {
          path: 'guests',
          name: 'graduationGuests',
          component: () => import('@/views/graduation/guests-view.vue'),
          beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const graduationStore = useGraduationStore();
            await userStore.getUser();
            await graduationStore.getBookingStatus();
            if (userStore.visBookUser && graduationStore.bookingOpen === BookingStatus.OPEN && graduationStore.cart.selectedRoom) {
              next();
            }
            else {
              next({ name: 'graduationHome' });
            }
          },
        },
        {
          path: 'customer',
          name: 'graduationCustomer',
          component: () => import('@/views/graduation/customer-view.vue'),
          beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const graduationStore = useGraduationStore();
            await userStore.getUser();
            await graduationStore.getBookingStatus();
            if (userStore.visBookUser && graduationStore.bookingOpen === BookingStatus.OPEN && graduationStore.cart.selectedRoom) {
              next();
            }
            else {
              next({ name: 'graduationHome' });
            }
          },
        },
        {
          path: 'checkout',
          name: 'graduationCheckout',
          component: () => import('@/views/graduation/checkout-view.vue'),
          beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const graduationStore = useGraduationStore();
            await userStore.getUser();
            await graduationStore.getBookingStatus();
            if (userStore.visBookUser && graduationStore.bookingOpen === BookingStatus.OPEN && graduationStore.cart.selectedRoom) {
              next();
            }
            else {
              next({ name: 'graduationHome' });
            }
          },
        },
      ],
    },
    { path: '/:pathMatch(.*)*', name: 'not-found', component: notFound },
  ],
});

export default router;
