interface TicketTypes {
  adult: number;
  child: number;
  infant: number;
}

interface ReunionTicketIds {
  earlyBird: TicketTypes;
  fullPrice: TicketTypes;
}

export enum ReunionMode {
  earlyBird = 'earlyBird',
  fullPrice = 'fullPrice',
}

interface Config {
  baseApi: string;
  baseUrl: string;
  reunionId: number;
  mode: ReunionMode;
  specialReunionId: number;
  peacebuildingId: number;
  graduationId: number;
  reunionTicketIds: ReunionTicketIds;
}

const config: Config = {
  baseApi: import.meta.env.MODE === 'production' ? 'https://bookings.uwcconnect.com/api' : 'http://localhost:5173/api',
  baseUrl: import.meta.env.MODE === 'production' ? 'https://bookings.uwcconnect.com' : 'http://localhost:5173',
  reunionId: 10405,
  specialReunionId: 11084,
  peacebuildingId: 11093,
  graduationId: 9394,
  mode: ReunionMode.fullPrice,
  reunionTicketIds: {
    earlyBird: {
      adult: 108,
      child: 103,
      infant: 104,
    },
    fullPrice: {
      adult: 112,
      child: 113,
      infant: 113,
    },
  },
};

export default config;
