import type { PluginOptions } from 'vue-toastification';

import { createHead } from '@unhead/vue/client';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import Toast from 'vue-toastification';

import App from './app.vue';
import './assets/main.css';
import router from './router';

// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

const options: PluginOptions = {
  // You can set your default options here
};

const app = createApp(App);
const head = createHead();

app.use(createPinia());
app.use(Toast, options);
app.use(router);
app.use(head);

app.mount('#app');
